import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'
// import Message from './Message'
// import Loader from './Loader'
// import Meta from './Meta'


import ShareTyzlleCard from './ShareTyzlleCard'


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceDetect
} from "react-device-detect";



export const TyzlleCompleted = ({ tyzlle, puzzle_id, isCompleted, score}) => {


  const dispatch = useDispatch()

  const tyzlleViz = useSelector(state => state.tyzlleViz)
  const { attempts, vizColorgrid } = tyzlleViz


  const [viewShare, setViewShare] = useState(false)

  const [finalColorgrid, setFinalColorgrid] = useState([])

  const [finalShare, setFinalShare] = useState("")

  const toggleViewShare = (e) =>{
    e.preventDefault()

    setViewShare(viewShare => viewShare =! viewShare)

  }

  var [nrows, setNRows] = useState(8)
  var [ncols, setNCols] = useState(8)
  var [colorgrid, setColorgrid] = useState({})

//   const colormap = {
//     '-1':'#ffffff',
//     '0':'#5f6e63', //'#c8ccc9',
//     '1':'#e012d6',
//     '2':'#d51cd6',
//     '3':'#ca26d7',
//     '4':'#bf30d7',
//     '5':'#b43ad8',
//     '6':'#a944d8',
//     '7':'#9e4ed9',
//     '8':'#9458d9',
//     '9':'#8962da',
//     '10':'#7e6cda',
//     '11':'#7377db',
//     '12':'#6881db',
//     '13':'#5d8bdc',
//     '14':'#5395dc',
//     '15':'#489fdd',
//     '16':'#3da9dd',
//     '17':'#32b3de',
//     '18':'#27bdde',
//     '19':'#1cc7df',
//     '20':'#12d2e0'

// }


const colormap = {
  '-1':'#ffffff',
  '0':'#5f6e63', //'#c8ccc9',

  '1':'#2d1ff0',
  '2':'#588aed',
  '3':'#69bef0',
  '4':'#88eaf7',

  '5':'#0c7d1f',
  '6':'#23ad3a',
  '7':'#62c959',
  '8':'#b4ed9a',

  '9':'#dff026',
  '10':'#d6e34b',
  '11':'#dade78',
  '12':'#e5e8a5',
  
  '13':'#eb9c15',
  '14':'#edb337',
  '15':'#f5c867',
  '16':'#f2d491',

  '17':'#f03f0e',
  '18':'#ed572d',
  '19':'#f27350',
  '20':'#f29b83'

}


//blue, green, yellow, orange, red
const emojimap = {
  '-1':'⬜',
  '0':'⬛',
  '1':'🟦', //blue
  '2':'🟦', //blue
  '3':'🟦', //blue
  '4':'🟦', //blue
  '5': '🟩', //green
  '6': '🟩', //green
  '7': '🟩', //green
  '8': '🟩', //green

  '9': '🟨', //yellow,
  '10': '🟨', //yellow,
  '11': '🟨', //yellow,
  '12': '🟨', //yellow,

  '13':'🟧', //orange
  '14':'🟧', //orange
  '15':'🟧', //orange
  '16':'🟧', //orange

  '17':'🟥', //red
  '18':'🟥', //red
  '19':'🟥', //red
  '20':'🟥' //red

}


  var colorScheme = []

  const generateFinalGrid = () => {

    console.log("Attempts ", attempts)

      var locColorGrid = tyzlle.colorgrid

      var tempEmojigrid = []

      //create final share grid 
      for(var i=0; i<locColorGrid.length; i++){
        tempEmojigrid.push(emojimap[locColorGrid[i]])
      }


      for(var iV=0; iV<attempts.length+1; iV++){

          if(attempts[iV]){
            var cAttempt = attempts[iV]
            console.log("iV ", iV, cAttempt)
            for(var iA=0; iA<cAttempt.length; iA++){
  
                if(iV < 19){
                  if(locColorGrid[cAttempt[iA]] <= 0){
                    locColorGrid[cAttempt[iA]] = iV+1
                    tempEmojigrid[cAttempt[iA]] = emojimap[iV+1]
                    console.log(" Cg ", colormap[iV+1])
                  }
                  
                }
                
            }

            setFinalColorgrid(locColorGrid)
            //setFinalShare(tempEmojigrid)
          }


      }

      console.log("locColorGrid ", locColorGrid)

      setFinalColorgrid(locColorGrid)
      //setFinalShare(tempEmojigrid)

      console.log("Final Share", finalShare)

      //add linespaces after every 10th element
      var finalSpacing = []
      for(var i=0; i<parseInt(tyzlle.nrows); i++){
        for(var j=0;j<parseInt(tyzlle.ncols); j++){
          finalSpacing.push(tempEmojigrid[i*10 + j])
        }
        finalSpacing.push("\n")
      }

      
      console.log("Finalshare ", finalSpacing.toString())
      setFinalShare(finalSpacing.toString().replaceAll(",",""))

  }



  useEffect(() => {

    if(isCompleted == true){
      generateFinalGrid()
    }
    

  }, [tyzlle, isCompleted, attempts]);


  const submitHandler = (e) => {
    e.preventDefault()
    
    
   }

   const teachyaaryoutubeclick = () =>{

      const tyyoutubelink = 'https://www.youtube.com/channel/UCbHYybvN5GM1z038VwNDtYQ'

   }

  
  return (

    <Card style={{padding:'5px', margin:'10px',border:'None', borderRadius:'15px'}}>


    
        
        {isCompleted && (
        <Card.Header style={{padding:'20px', margin:'0px', fontWeight:'800', borderRadius:'15px'}}>


          <p className='h3' style={{color:'#00416a', textAlign:'center', marginBottom:'20px'}}> Hope you enjoyed the <span style={{color:'#8b008b'}}>TYZLLE</span>  </p>

          {/* <table>
            <tr>
              <td>1-4</td>
              <td>5-8</td>
              <td>9-12</td>
              <td>13-16</td>
              <td>17-20</td>
            </tr>
            <tr>
              <td style={{
                          backgroundColor: colormap[1], 
                          opacity:'0.8', 
                          width: isMobile ? '12vw' :'6vh', 
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px',
                          marginRight:'2px'
                      }}><p></p></td>
              <td style={{
                          backgroundColor: colormap[5], 
                          opacity:'0.8', 
                          width: isMobile ? '12vw' :'6vh', 
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px',
                          marginRight:'2px'
                      }}><p></p></td>
              <td style={{
                          backgroundColor: colormap[9], 
                          opacity:'0.8', 
                          width: isMobile ? '12vw' :'6vh', 
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px',
                          marginRight:'2px'
                      }}><p></p></td>
              <td style={{
                          backgroundColor: colormap[13], 
                          opacity:'0.8', 
                          width: isMobile ? '12vw' :'6vh',  
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px',
                          marginRight:'5px'
                      }}><p></p></td>
              <td style={{
                          backgroundColor: colormap[17], 
                          opacity:'0.8', 
                          width: isMobile ? '12vw' :'6vh', 
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px',
                          marginRight:'5px'
                      }}><p></p></td>
            </tr>
          </table> */}


            {/* <p className='h3' style={{color:'#00416a'}}> Your Score is {score}. Hope you enjoyed the <span style={{color:'#8b008b'}}>TYZLLE</span>  </p> */}
            <Card style={{padding:'5px', border:'None'}}>


            <Card.Body   style={{display:'flex', alignItems:'center', justifyContent:'center', border:'None', borderColor:"#8b008b", margin:'10px', padding:'5px'}}>

                              
{tyzlle && (

      <> 

      <table striped bordered hover style={{width: isMobile?'90vw' : '30vh', height: isMobile?'90vw':'30vh',    
          borderCollapse:'separate',              
          borderSpacing:'0px',
          borderWidth:'1px'
          }}>
      <tbody>

      {Array(tyzlle.nrows).fill(0).map((val, index1) =>(

          <tr key={100 + index1}>

              {Array(tyzlle.ncols).fill(0).map((val, index2) =>(

                  <>
                  
                      <td style={{
                          backgroundColor: colormap[finalColorgrid[index1*10+index2]], 
                          opacity:'0.8', 
                          width: isMobile ? '8vw' :'4vh', 
                          height: isMobile ? '8vw' :'4vh', 
                          border:'solid', //'solid',
                          textAlign: 'center', verticalAlign: 'middle',
                          borderColor: 'white',
                          padding:'1px',
                          margin:'0px',
                          borderCollapse:'separate',
                          borderSpacing:'1px'
                      }} >

                          <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{' '}</p>
                  
                      </td>

                  </>

              ))}
              
          
          </tr>

      ))}


      </tbody>
      </table>

      </>
      )}

      </Card.Body>

            </Card>
           


           
            {/* <p style={{color:'#00416a'}}>You can try previous <span style={{color:'#8b008b'}}>TYZLLES</span> in our <Link to='/morepuzzles' className='h5' style={{color:'#8b008b'}}>More Puzzles</Link> section.</p>

            <p>To get notified about new puzzles coming out check out/subscribe to the <span> <Link onClick={()=>{window.open('https://www.youtube.com/channel/UCbHYybvN5GM1z038VwNDtYQ')}}> TeachYaar Youtube Channel  <i class="fab fa-youtube" style={{padding:'5px'}}></i></Link></span>.</p> */}

            {/* <ShareCompletedPuzzleCard puzzle_id={puzzle_id}  /> */}

            
            <ShareTyzlleCard puzzle_id={puzzle_id}  values={finalShare} title={tyzlle.title}/>

        </Card.Header>
        )}




    </Card>


  );
};

export default TyzlleCompleted;
