export const LOAD_TYZLLE_CREATOR  = 'LOAD_TYZLLE_CREATOR'
export const LOAD_TYZLLE_CREATOR_ERROR  = 'LOAD_TYZLLE_CREATOR_ERROR'

export const LOAD_TYZLLE_LIST_CREATOR  = 'LOAD_TYZLLE_LIST_CREATOR'
export const LOAD_TYZLLE_LIST_CREATOR_ERROR  = 'LOAD_TYZLLE_LIST_CREATOR_ERROR'

export const UPDATE_TYZLLE_CREATOR  = 'UPDATE_TYZLLE_CREATOR'
export const UPDATE_TYZLLE_CREATOR_ERROR  = 'UPDATE_TYZLLE_CREATOR_ERROR'

export const ADD_TYZLLE_CREATOR   = 'ADD_TYZLLE_CREATOR'
export const POST_TYZLLE_CREATOR  = 'POST_TYZLLE_CREATOR'
