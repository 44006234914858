import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {TyzlleReducer, TyzlleListReducer, TyzlleVizReducer} from './reducers/tyzlleReducer'
import {TyzlleCreatorListReducer, TyzlleCreatorReducer} from './reducers/creatorReducer'
import {UserReducer} from './reducers/userReducer'

const reducer = combineReducers({
    tyzlleR:TyzlleReducer,
    tyzlleList: TyzlleListReducer,
    tyzlleViz: TyzlleVizReducer,

    tyzlleCList:TyzlleCreatorListReducer, 
    tyzlleCR:TyzlleCreatorReducer,

    userR: UserReducer,


})



const userInfoItemsFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {

    userR: { user: userInfoItemsFromStorage },

} 

const middleware = [thunk]
const store = createStore(
    reducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store