import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

export const VirtualKeyboardCard = ({setWordKeyboardButtons}) => {


  const dispatch = useDispatch()




  useEffect(() => {



  }, []);


  const submitHandler = (e) => {
    e.preventDefault()
    
    
   }



  
  return (

    <>

<div class="virtual-keyboard">

    <div class="keyboard-row">
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('q')}>Q</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('w')}>W</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('e')}>E</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('r')}>R</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('t')}>T</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('y')}>Y</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('u')}>U</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('i')}>I</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('o')}>O</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('p')}>P</button>
    </div>

    <div class="keyboard-row">
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('a')}>A</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('s')}>S</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('d')}>D</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('f')}>F</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('g')}>G</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('h')}>H</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('j')}>J</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('k')}>K</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('l')}>L</button>

    </div>

    <div class="keyboard-row">
    <button class="virtual-key special-key" onClick={()=>setWordKeyboardButtons('ENTER')}>Enter</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('z')}>Z</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('x')}>X</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('c')}>C</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('v')}>V</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('b')}>B</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('n')}>N</button>
        <button class="virtual-key" onClick={()=>setWordKeyboardButtons('m')}>M</button>
        <button class="virtual-key special-key" onClick={()=>setWordKeyboardButtons('CLEAR')}>⌫</button>
    </div>
</div>





    </>


  );
};

export default VirtualKeyboardCard;
