import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title> {title}</title>
            <meta name='description' content={description}/>
            <meta name='keywords' content={keywords}/>
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Tyzlle : Crossword Game',
    description: 'Stimulate your brain and improve your vocabulary with Tyzlle',
    keywords: 'tyzlle, wordle, 5 letter words, crosswords, word games, brain teasers'
}

export default Meta
