import {

    LOGIN_USER,
    LOGIN_FAIL,
    LOGOUT_USER

} from '../constants/userConstants.js';


export const UserReducer = (state = { user: null}, action) => {

    switch(action.type){
        case LOGIN_USER:
            return { loading: false, user: action.payload }
        case LOGIN_FAIL:
            return { loading: false, error: action.payload, user:state.user }
        case LOGOUT_USER:
            return { loading: false, user:null }
        default:
            return state
    }

}

