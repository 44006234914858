import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup, Badge} from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';

import {logoutUser} from '../actions/userActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const LogoutScreen = ({ match, history }) => {

    const dispatch = useDispatch()



    useEffect(() => {
        console.log("Logging out user")
        dispatch(logoutUser())
        history.push('/')

    }, [])



    return (
        <>


            <p className='h3'>Logging you out</p>


        
        </>
    )
}


export default LogoutScreen
