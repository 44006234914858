import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'
// import Message from './Message'
// import Loader from './Loader'
// import Meta from './Meta'



export const TyzlleInstructions = ({ title }) => {


  const dispatch = useDispatch()

  const [viewInstructions, setViewInstructions] = useState(false)
  
  const toggleInstructions = (e) => {

    e.preventDefault();

    setViewInstructions(viewInstructions => viewInstructions =  !viewInstructions)

  }



  useEffect(() => {



  }, []);


  const submitHandler = (e) => {
    e.preventDefault()
    
   }

  
  return (

    <Container>

<Card style={{padding:'5px', margin:'10px',border:'None', borderRadius:'10px'}}>

{/* <Button onClick={toggleInstructions} style={{backgroundColor:'#8b008b'}}>Click to Check out the Instructions.</Button>

{viewInstructions && ( */}
<Card.Header style={{padding:'0px', margin:'0px', fontWeight:'800'}}>

<ListGroup>
    <ListGroup.Item>
        <p className='h5'>The crossword uses only 5 letter words. </p>
    </ListGroup.Item>
    <ListGroup.Item>
        <p className='h5'>Select any of the cross words and enter  a 5 letter word.</p>
    </ListGroup.Item>
    <ListGroup.Item>
        <p className='h5'>Any letter that matches the solution, shows up in green in the selected word.</p>
    </ListGroup.Item>
    <ListGroup.Item>
        <p className='h5'>If any other word in the crossword has the letters in the same position, they also show up in green.</p>
    </ListGroup.Item>
    <ListGroup.Item>
        <p className='h5'>Letters that are present in the selected word but in a different position show up in yellow.</p>
    </ListGroup.Item>
    <ListGroup.Item>
        <p className='h5'>You have 20 word guesses to complete the crossword.</p>
    </ListGroup.Item>
    {/* <ListGroup.Item>
        <p className='h5'>Check out the worked example if you have any doubts.  </p>
    </ListGroup.Item> */}
</ListGroup>

</Card.Header>
{/* )} */}




</Card>

    </Container>




  );
};

export default TyzlleInstructions;
