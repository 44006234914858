import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'
// import Message from './Message'
// import Loader from './Loader'
// import Meta from './Meta'


import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";

  import QRCode from "react-qr-code"

export const ShareTyzlleCard = ({ puzzle_id, values, title }) => {


  const dispatch = useDispatch()

  const [shareView, setShareView] = useState(false)
  const toggleShareView = (e) => {

    e.preventDefault()
    setShareView( shareView => shareView =! shareView)
  }

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(title + '\n' + values);
      alert('Tyzlle result Copied to Clipboard');
    } catch (err) {
      alert('Unable to Copy: ', err);
    }
  };



  useEffect(() => {



  }, []);


  const submitHandler = (e) => {
    e.preventDefault()
    
   }

  
  return (

    <Card style={{padding:'5px', border:'None'}}>

        {/* <InputGroup>
            <Button onClick={toggleShareView} style={{backgroundColor:'#8b008b'}}> Share</Button>
        </InputGroup>

        {shareView && ( */}

        <Card.Header style={{padding:'5px', margin:'5px'}}>
  
        {/* <p><span>  Link :  https://www.teachyaar.com/viewTyzlle/{puzzle_id} </span> <span><Button style={{borderRadius:'20px', padding:'5px', backgroundColor:'gray'}}><i className="fa fa-copy"></i></Button></span></p> */}

        {/* <p><span>  Link :  https://www.teachyaar.com/tyzlles/{puzzle_id} </span> <span><Button style={{borderRadius:'20px', padding:'5px', backgroundColor:'gray'}}><i className="fa fa-copy"></i></Button></span></p> */}

        {/* <p><span>  Link :  https://www.teachyaar.com/tyzlles/{puzzle_id} </span> </p> */}


            {/* <Row>
                <Col >
                
                <QRCode value={"https://www.teachyaar.com/tyzlles/"+puzzle_id} size={86}/>
                    <p>Scan to share this <span style={{color:'#8b008b'}}>TYZLLE</span></p>
                </Col>
            </Row> */}
            <Row>
                <Col style={{padding:'5px', margin:'5px'}}>


            <Badge onClick={()=>copyTextToClipboard()} style={{borderRadius:'50%', border:'solid', padding:'10px'}}><i className="fa fa-share-alt" aria-hidden="true"></i></Badge>

            <FacebookShareButton url={'https://tyzlle.com'} title={title + '\n' + values} hashtag='#tyzlle'>
                <FacebookIcon round size={32} />
            </FacebookShareButton>

            <WhatsappShareButton url={'https://tyzlle.com'} title={title + '\n' + values} hashtag='#tyzlle'>
                <WhatsappIcon round size={32} />
            </WhatsappShareButton>

            {/* <EmailShareButton url={'https://tyzlle.com'} title={'Try out this TYZLLE'} body='Hi, Enjoy this TYZLLE from TeachYaar' subject={'Try out thus TeachYaar Puzzle (TYZLLE) link'} separator=" :   ">
                <EmailIcon round size={32}  />
            </EmailShareButton> */}
                
                </Col>
            </Row>



        </Card.Header>


        {/* )} */}

        



    </Card>


  );
};

export default ShareTyzlleCard;
