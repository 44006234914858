import axios from 'axios';
import {

    LOAD_TYZLLE_CREATOR,
    LOAD_TYZLLE_CREATOR_ERROR,

    LOAD_TYZLLE_LIST_CREATOR,
    LOAD_TYZLLE_LIST_CREATOR_ERROR,

    UPDATE_TYZLLE_CREATOR,
    UPDATE_TYZLLE_CREATOR_ERROR,

    POST_TYZLLE_CREATOR


} from '../constants/creatorConstants'





export const TyzlleCreatorListReducer = (state = {ctyzlles:[]}, action) => {

    switch(action.type){

        case LOAD_TYZLLE_LIST_CREATOR:
            return { loading: false, ctyzlles: action.payload }

        case LOAD_TYZLLE_LIST_CREATOR_ERROR:
            return { loading: false, error: action.payload, ctyzlles:state.ctyzlles }


        default:
            return state
    }


}





export const TyzlleCreatorReducer = (state = {tyzlle: null, solution:null}, action) => {

    switch(action.type){

        case LOAD_TYZLLE_CREATOR:
            return { loading: false, tyzlle: action.payload.tyzlle, solution:action.payload.sol }


        case LOAD_TYZLLE_CREATOR_ERROR:
            return { loading: false, error: action.payload, tyzlle: action.payload.tyzlle, solution:action.payload.sol }


        case UPDATE_TYZLLE_CREATOR:
            return { loading: false, tyzlle: action.payload.tyzlle, solution:action.payload.sol }


            
        case POST_TYZLLE_CREATOR:
            return { loading: false, tyzlle: action.payload, solution:state.solution }


        default:
            return state
    }


}
