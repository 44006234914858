import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'


import TyzllePlay from '../components/tyzlle/TyzllePlay'

import {loadTyzlle, startTyzlle, tryWordTyzlle, loadTyzlleUrl} from '../actions/tyzlleActions'



export const TyzlleScreen = ({match, history, socket}) => {


    const dispatch = useDispatch()


    useEffect(() => {

        dispatch(loadTyzlle())
    }, [])


    //const last7 = []



    return (

        <>
               <Meta title={'Tyzlle, a crossword to improve our vocabulary'} description={'A wordle like word game. Mixture of a wordle and a crossword. Play to improve your vocabulary'} keywords={'word games, puzzles, tyzlle, crossword, english game, vocabularygame,game, fun, viral, wordle, word guessing'}/>

                <TyzllePlay/>

        </>
    )
}


export default TyzlleScreen



