import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import {loadTyzlleList} from '../actions/tyzlleActions'

import TyzlleListView from '../components/tyzlle/TyzlleListView'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";






export const TyzlleListScreen = ({match, history}) => {


    const dispatch = useDispatch()

    const tyzlleList = useSelector(state => state.tyzlleList)
    const { tyzlles } = tyzlleList


    const playTyzlleRequest = (tyzlle_url) => {
        history.push(`/play/${tyzlle_url}`)
    }


    useEffect(() => {

        dispatch(loadTyzlleList())
    }, [])


    //const last7 = []



    return (

        <>
               <Meta title={'A List of all Previous Tyzlles'} description={'A list of all previously played tyzlles. A wordle like word game. Mixture of a wordle and a crossword. Play to improve your vocabulary'} keywords={'word games, puzzles, tyzlle, crossword, english game, vocabularygame,game, fun, viral, wordle, word guessing'}/>

                <div style={{margin:'20px'}}>
                {!isMobile && (
                    <Row>
                    {tyzlles && tyzlles.map((ctyzlle, index)=>(
                        <Col style={{maxWidth:isMobile?'70vw':'30vw', minWidth:isMobile?'60vw':'20vw'}} onClick={()=>playTyzlleRequest(ctyzlle.url)}>
                        <TyzlleListView tyzlle={ctyzlle} />
                    </Col>
                    ))}
                    

                    </Row>
                     )}


                {/* <Row>
                {tyzlles && tyzlles.map((ctyzlle, index)=>(
                    <Col style={{maxWidth:isMobile?'70vw':'30vw', minWidth:isMobile?'60vw':'20vw'}} onClick={()=>playTyzlleRequest(ctyzlle.url)}>
                        <TyzlleListView tyzlle={ctyzlle} />
                    </Col>
                ))}
                </Row> */}

                {isMobile && (
                    <ListGroup>
                        {tyzlles && tyzlles.map((ctyzlle, index)=>(
                        <ListGroup.Item onClick={()=>playTyzlleRequest(ctyzlle.url)} style={{border:'None'}}>

                        <TyzlleListView tyzlle={ctyzlle} />
                        </ListGroup.Item>

                        ))}
                    </ListGroup>
                )}

                </div>




        </>
    )
}


export default TyzlleListScreen



