import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import FormContainer from '../../components/FormContainer'

import Dropdown from 'react-bootstrap/Dropdown';

import {updateTyzlle, postTyzlle} from '../../actions/creatorActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const TyzlleCreate = ({tyzlle, solution}) => {


    const dispatch = useDispatch()
    const history = useHistory()


    const [colorgrid, setColorgrid] = useState({})

    const [postDate, setPostDate] = useState('')

    const [wordSelection, setWordSelection ] = useState(0)
    const [wordEntered, setWordEntered] = useState([])
    const [wgrid, setWGrid] = useState({})
    const [letters, setLetters] = useState({})

    const [updateInvisible, setUpdateInvisible] = useState(0)

    const [words, setWords] = useState([
        {
            word:'',
            position:'',
            orientation:'',
            letters:[],
            positions:[]
        },
        {
            word:'',
            position:'',
            orientation:'',
            letters:[],
            positions:[]
        },
        {
            word:'',
            position:'',
            orientation:'',
            letters:[],
            positions:[]
        },
        {
            word:'',
            position:'',
            orientation:'',
            letters:[],
            positions:[]
        },
        {
            word:'',
            position:'',
            orientation:'',
            letters:[],
            positions:[]
        }
    ])
    


    const [selectedWord, setSelectedWord ] = useState('0')





    const [word1, setWord1 ] = useState('')
    const [word2, setWord2 ] = useState('')
    const [word3, setWord3 ] = useState('')
    const [word4, setWord4 ] = useState('')
    const [word5, setWord5 ] = useState('')

    const [position1, setPosition1 ] = useState('')
    const [position2, setPosition2 ] = useState('')
    const [position3, setPosition3 ] = useState('')
    const [position4, setPosition4 ] = useState('')
    const [position5, setPosition5 ] = useState('')

    const [orientation1, setOrientation1 ] = useState('h')
    const [orientation2, setOrientation2 ] = useState('h')
    const [orientation3, setOrientation3 ] = useState('h')
    const [orientation4, setOrientation4 ] = useState('h')
    const [orientation5, setOrientation5 ] = useState('h')


    const [showInstructions, setShowInstructions] = useState(false)

    // const tyzlleCR = useSelector(state => state.tyzlleCR)
    // const { tyzlle, solution } = tyzlleCR


    // const tyzlleViz = useSelector(state => state.tyzlleViz)
    // const { vizColorgrid } = tyzlleViz



    const alphabets_list = 'abcdefghijklmnopqrstuvwxyz'.split('');


    const colormap = {
        '-1':'#ffffff',
        '0':'#5f6e63', //'#c8ccc9',
        '1':'#bfab47',
        '2':'#4bbf73' //'#4fa163'
    }
    // '0':'#c7c2bd'






    const updateWord = (windex) => {


        var lwords = words

        console.log("Selected Word ", selectedWord)

        var cword = {
            word:word1,
            position:position1,
            orientation:orientation1,
            positions:[],
            letters:[]
        }

        var orientationScale = 1
        if(orientation1 == 'h'){
            orientationScale = 1
        }else{
            orientationScale = 10
        }

        var lpositions = []
        var lletters = []

        for(var i=0; i<word1.length; i++){

            var cpos = parseInt(position1) + i*orientationScale

            lpositions.push(cpos)
            lletters.push(word1[i])

        }

        cword.positions = lpositions
        cword.letters   = lletters

        lwords[parseInt(selectedWord)-1] = cword

        console.log("words ", lwords)

        setWords(lwords)

        RegenGrid(lwords)


    }





    const RegenGrid = (lwords) => {

            //Get starting Position

            var lettersLoc = {}



            console.log("Letters : ", lettersLoc)

            for (var iW=0; iW<5; iW++){

                var word = lwords[iW].word
                var position = parseInt(lwords[iW].position)
                var orientation = lwords[iW].orientation
                

                console.log("w ", iW, word, word.length, position, orientation)
               
                var orientationScale = 1
                if(orientation == 'h'){
                    orientationScale = 1
                }else{
                    orientationScale = 10
                }
    

    
                for(var i=0; i<word.length; i++){
    
                    var cpos = position + i*orientationScale
                    
                    lettersLoc[cpos] = word[i]


                }
            }

            console.log("Let ", lettersLoc)
        
            setLetters(lettersLoc)

            setUpdateInvisible(updateInvisible + 1)


    }




    const updateTyzlleRequest = () => {

        //calculate nrows

        var nRows = 0
        var nCols = 0
        for(var i=0; i<words.length; i++){
            for(var j=0; j<5; j++){


                var cRow = 0
                var cCol = 0
                if(words[i].positions[j] < 10){
                    cRow = 0 + 1
                    cCol = parseInt(words[i].positions[j].toString()) + 1
    
                }else{
                    cRow = parseInt(words[i].positions[j].toString()[0]) + 1
                    cCol = parseInt(words[i].positions[j].toString()[1]) + 1 
    
                }
               

                if(cRow > nRows){
                    nRows = cRow
                }

                if(cCol > nCols){
                    nCols = cCol
                }
            }
        }

        var nrows = nRows
        var ncols = nCols
        var updatedWords = []
        for(var i=0; i< words.length ; i++){

            var cW = {
                word:words[i].letters,
                positions: words[i].positions
            }

            updatedWords.push(cW)

        }


        dispatch(updateTyzlle(tyzlle._id, nrows, ncols, updatedWords))


    }




    const updatePostDate = () => {

        console.log("Date Posted ", postDate.toString())

        var dateSplit = postDate.toString().split('-')
        var year = dateSplit[0]
        var month = dateSplit[1]
        var day =  dateSplit[2]

        var reformattedDate = day + '-' + month + '-' + year

        console.log("Reformatted ", reformattedDate)

        dispatch(postTyzlle(tyzlle._id, reformattedDate))

    }
  


    useEffect(() => {


        // if(!isMobile){

        
        //     window.addEventListener("keyup", DocumentKeyUp);
    
        //     return () => {
        //       window.removeEventListener("keyup", DocumentKeyUp);
        //     };
    
        // }

        if(tyzlle){
            var dor = tyzlle.dateofrelease
            var dorSplit = dor.split('-')

            var dateReform = dorSplit[2] + '-' + dorSplit[1] + '-' + dorSplit[0]

            setPostDate(dateReform)

        }


        if(solution.words && solution.words.length>0){
            var lwords = []

            for(var iW=0; iW<5; iW++){
                var startingPosition = solution.words[iW].positions[0]
                
                var cword = ''
                for(var iW2=0; iW2<5; iW2++){
                    cword = cword + solution.words[iW].word[iW2]
                }

                var corient = 'h'

                if((solution.words[iW].positions[1] - solution.words[iW].positions[0]) == 1){
                    corient = 'h'
                }else{
                    corient = 'v'
                }

                var currword = {
                    word:cword,
                    position:startingPosition,
                    orientation:corient,
                    positions:solution.words[iW].positions,
                    letters:solution.words[iW].word
                }

                lwords.push(currword)
            
            
            }

            console.log("Words in ", lwords)

            setWords(lwords)

            RegenGrid(lwords)


        }
     

       
    
    

        

    }, [])


    return (

        <Container>

            <Card className="d-flex" style={{border:'None'}}>
            <Row className="align-self-center">
                <Col style={{border:'None'}}>


                <Card  style={{border:'None', padding:'5px'}}>


                <Card.Header style={{borderRadius:'20px', padding:'1px'}}>

                {
                    tyzlle && (
                        <p className='h3' style={{textAlign:'center', paddingTop:'0px', paddingBottom:'0px'}}>Creator : {tyzlle.title} </p>

                    )
                }

                </Card.Header>

            
                <Row>
                    <Col>

                    <Card.Body   style={{display:'flex', alignItems:'center', justifyContent:'center', border:'None', borderColor:"#8b008b", margin:'10px', padding:'5px'}}>

                        
                {tyzlle && (

                    <> 

                <table striped bordered hover style={{width: isMobile?'90vw' : '50vh', height: isMobile?'90vw':'50vh',    
                        borderCollapse:'separate',              
                        borderSpacing:'0px',
                        borderWidth:'1px',
                        padding:'10px',
                        border:'solid', borderColor:"#8b008b"
                        }}>
                    <tbody>

                    {Array(10).fill(0).map((val, index1) =>(

                        <tr key={100 + index1}>

                            {Array(10).fill(0).map((val, index2) =>(

                                <>
                                
                                    <td style={{
                                        backgroundColor: (colorgrid && colorgrid[index1*10+index2]) ? colormap[colorgrid[index1*10 + index2]] : colormap[0], 
                                        opacity:'0.8', 
                                        width: isMobile ? '8vw' :'4vh', 
                                        height: isMobile ? '8vw' :'4vh', 
                                        border:'solid', //'solid',
                                        borderWidth:'1px',
                                        textAlign: 'center', verticalAlign: 'middle',
                                        borderColor: 'black',
                                        padding:'1px',
                                        margin:'0px',
                                        borderCollapse:'separate',
                                        borderSpacing:'1px'
                                    }} >
                                        
                      


                                        { (letters && letters[index1*10 + index2]) ? (
                                             <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{letters[10*index1 + index2]}</p>
                                         ) : (
                                             <>
                                                
                                                {wgrid && wgrid[index1*10 + index2] && (
                                                     <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{wgrid[10*index1 + index2]}</p>
                                                 )}
                                             </>
                                        )}

                                        


                                    </td>

                                </>

                            ))}
                            
                        
                        </tr>

                    ))}


                    </tbody>
                </table>

                </>
                )}

                </Card.Body>

                    
                    
                    </Col>
                    <Col>

                    <p style={{color:'white'}}>{updateInvisible}</p>
                    

                    <p>Post Date : {postDate}</p>

                    <Card>
                        <Card.Header>

                            <InputGroup>
                            <Form.Control type='date' placeholder='Post Date' value={postDate} onChange={(e) => setPostDate(e.target.value)}> 
                            </Form.Control>

                            <Button onClick={()=>updatePostDate()}>Post</Button>

                            </InputGroup>
                           

                        </Card.Header>
                    </Card>
                    

                    <br />

                    <Table bordered>

                        <tbody>

                            <tr>
                                <td>
                                    Word No
                                </td>
                                <td>
                                    Starting Position
                                </td>
                                <td>
                                    Horizontal/Vertical
                                </td>
                                <td>
                                    Word
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Control as='select' placeholder='0' value={selectedWord} onChange={(e) => setSelectedWord(e.target.value)}> 
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Control type='text' placeholder='Starting Position' value={position1} onChange={(e) => setPosition1(e.target.value)}> 
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Control as='select' placeholder='H or V' value={orientation1} onChange={(e) => setOrientation1(e.target.value)}> 
                                    <option value="h">H</option>
                                    <option value="v">V</option>
                                    </Form.Control>
                                </td>
                                <td>
                                <Form.Control type='text' placeholder='Word 1' value={word1} onChange={(e) => setWord1(e.target.value)}> 
                                    </Form.Control>
                                </td>
                                <td>
                                    <Button onClick={()=>updateWord('1')}>+</Button>
                                </td>
                            </tr>

                            <hr/>

                            {words && words.map((cword, windex)=>(

                                <tr style={{backgroundColor: (windex+1).toString() == selectedWord ? '#f6f7a1' : 'white'}}>
                                <td className='h5'>
                                    {windex+1}
                                </td>
                                <td className='h5'>
                                    {cword.word}
                                </td>
                                <td className='h5'>
                                    {cword.position}
                                </td>
                                <td className='h5'>
                                    {cword.orientation}
                                </td>
                                <td>
                                    
                                </td>
                            </tr>





                            ))}

                            

                        </tbody>

                    </Table>


                    <Button onClick={()=>updateTyzlleRequest()}>Create</Button>



                    </Col>
                </Row>

                

                {/* <Card className="d-flex" style={{border:'None', marginBottom:'15px', height:'4vw'}}>
                    <Row className="align-self-center">
                        <Col>
                            <table>
                                <tr>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[0]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[1]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[2]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[3]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[4]}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Card>



                <div style={{width:isMobile?'98vw':'50vw', padding:'10px'}}>
                    <VirtualKeyboardCard setWordKeyboardButtons={setWordKeyboardButtons}/>


                </div> */}
 


                </Card>

      



                </Col>
            </Row>

            </Card>


            </Container>
        
    )
    

}


export default TyzlleCreate




