import React from 'react'
import { useSelector } from 'react-redux'

const Alert = ({ })  => {
    

    const alertsV = useSelector(state => state.alerts)
    const { alerts } = alertsV

    return(

        alerts !== null && alerts.length > 0 && alerts.map(alert => (
            <div key={alert.id} className={`alert alert-${alert.alertType}`}>
                {alert.msg}
            </div>
        ))

    )

}



export default Alert;
