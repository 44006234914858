import React, { Fragment, useEffect } from 'react' 
import {  useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {Navbar, Nav, Container, NavDropdown, Img, Button, DropdownButton, Dropdown } from 'react-bootstrap'



const Header = () => {

    const dispatch = useDispatch()
    const history = useHistory();


    const userR = useSelector(state => state.userR)
    const { user } = userR


    const logoutHandler = () => {
  

    }




    useEffect(() => {


        

    }, [])


    return (
        <header className="header" style={{borderBottom:'solid'}}>

            <Navbar expand="lg" collapseOnSelect className="gradient-background" style={{padding:'0px'}}>
                
            {/* <Navbar bg="dark"  variant='dark' expand="lg" collapseOnSelect> */}
                
                <Container>

                <LinkContainer to='/'>
                    <span style={{backgroundColor:'white', padding:'0px'}}>
                <img className='tylogo'
                        src="/Tyzlle_logo.png"
                        // src="/images/ty_white_2.png"
                        //width="200"
                        height="60"
                        className="d-inline-block align-top"
                        alt="Tyzlle logo"
                        backgroundColor="white"
                        borderRadius='5px'
                    />
                    
                    </span>
                    
                </LinkContainer>


                <p className='h1' style={{textAlign:'center', marginLeft:'20px'}}>Tyzlle</p>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                {/* <Navbar.Collapse id="basic-navbar-nav"> */}


                <Nav className="ml-auto">

                <LinkContainer to='/'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}><i class="fa fa-home"></i></Button></Nav.Link>
                </LinkContainer>

                <LinkContainer to='/list'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>Previous</Button></Nav.Link>
                </LinkContainer>


                {user && user.token && (
                        <LinkContainer to='/admin3323/logout'>
                        <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>Logout</Button></Nav.Link>
                    </LinkContainer>
                    )}
                
                </Nav>
                {/* </Navbar.Collapse> */}
        </Container>
        </Navbar>
        </header>
    )
}

export default Header
