import axios from 'axios';
// import {setAlert} from './alertActions';
import {

    LOAD_TYZLLE,
    LOAD_TYZLLE_ERROR,
    INIT_TYZLLE,
    START_TYZLLE,
    START_TYZLLE_ERROR,
    TYZLLE_ATTEMPT,
    TYZLLE_ATTEMPT_ERROR,
    TYZLLE_CLEAR,
    TYZLLE_COMPLETED,
    TYZLLE_COMPLETED_ERROR,
    TYZLLE_RETAKE,

    LOAD_TYZLLE_LIST,
    LOAD_TYZLLE_LIST_ERROR,

    TYZLLE_VIZ_HISTORY,
    TYZLLE_VIZ_HISTORY_FAIL,
    TYZLLE_VIZ_HISTORY_INIT,
    TYZLLE_VIZ_COLORGRID

} from '../constants/tyzlleConstants'





//Load puzzle by id
export const loadTyzlleList = () => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/list`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)

        // if(data && data._id){
        //     localStorage.setItem('tyzzleId', JSON.stringify(data._id))
        // }

        
        dispatch({
            type: LOAD_TYZLLE_LIST,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_LIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const loadTyzlle = () => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/tyzlles`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)

        //check if tyzlle is previously attempted by user in local storage and if so them load previous state
        var tyzlle = data.tyzlle
        var colorgrid = data.resp.colorgrid
        var letters   = data.resp.letters
        var response = data.response  
        var isCompleted = data.isCompleted
        var wattempts = []
        var score = 0
        var correctLetters = []

        var loadedTyzlle = {
            "tyzlle":tyzlle,
            "resp":{
                "colorgrid":colorgrid,
                "letters":letters,
                "response":response,
                "isCompleted":false,
                "wattempts":[],
                "score":0,
                "correctLetters":[]
            }

        }

        console.log("Loaded Tyzlle clean ", loadedTyzlle)

        var colorgridCurr = []


        if(data.tyzlle && data.tyzlle._id){


            const existingResponse = localStorage.getItem("tyzlle_"+data.tyzlle._id) ? JSON.parse(localStorage.getItem("tyzlle_"+data.tyzlle._id)) : null


            console.log("Existing Resoonse", existingResponse)

            if(existingResponse && existingResponse.colorgrid.length>0){
                colorgrid    = existingResponse.colorgrid
                letters      = existingResponse.letters
                response     = existingResponse.response 
                isCompleted  = existingResponse.isCompleted
                wattempts    = existingResponse.wattempts
                score        = existingResponse.score
                
                if(existingResponse.correctLetters){
                    correctLetters = loadedTyzlle.resp.correctLetters
                }

                if(existingResponse.colorgridCurr){
                    colorgridCurr = existingResponse.colorgridCurr
                }

                for(var iC=0;iC<colorgrid.length; iC++){
                    if(colorgrid[iC] == 1){
                        colorgrid[iC] = 0
                    }
                }

                loadedTyzlle.resp.colorgrid = colorgrid
                loadedTyzlle.resp.letters = letters
                loadedTyzlle.resp.response = response
                loadedTyzlle.resp.isCompleted = isCompleted
                loadedTyzlle.resp.wattempts = wattempts
                loadedTyzlle.resp.score = score
                loadedTyzlle.resp.correctLetters = correctLetters


                colorgridCurr = existingResponse.colorgridCurr
        
                console.log("Exists Resoonse", loadedTyzlle)

            }else{
                var newTyzlleResp = {
                    "colorgrid":colorgrid,
                    "letters":letters,
                    "response":response,
                    "isCompleted":isCompleted,
                    "wattempts":[],
                    "score":0,
                    "correctLetters":[],
                    "colorgridCurr":[],
                }

                console.log("Doesnt Exists Resoonse", loadedTyzlle)

                localStorage.setItem("tyzlle_"+data.tyzlle._id, JSON.stringify(newTyzlleResp))

  
            }

        }


        // if(data && data._id){
        //     localStorage.setItem('tyzzleId', JSON.stringify(data._id))
        // }



        dispatch({
            type: LOAD_TYZLLE,
            payload: loadedTyzlle //data
        })

        dispatch({
            type:TYZLLE_VIZ_HISTORY_INIT,
            payload:colorgridCurr
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const loadTyzlleUrl = (tyzlle_url) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        console.log("TYZle url get ", tyzlle_url)


        const full_url = `/api/tyzlles/${tyzlle_url}`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)

                //check if tyzlle is previously attempted by user in local storage and if so them load previous state
                var tyzlle = data.tyzlle
                var colorgrid = data.resp.colorgrid
                var letters   = data.resp.letters
                var response = data.response  
                var isCompleted = data.isCompleted
                var wattempts = []
                var score = 0
                var correctLetters = []
        
                var loadedTyzlle = {
                    "tyzlle":tyzlle,
                    "resp":{
                        "colorgrid":colorgrid,
                        "letters":letters,
                        "response":response,
                        "isCompleted":false,
                        "wattempts":[],
                        "score":0,
                        "correctLetters":[]
                    }
        
                }

                var colorgridCurr = []
        
                console.log("Loaded Tyzlle clean ", loadedTyzlle)
        
                if(data.tyzlle && data.tyzlle._id){
        
        
                    const existingResponse = localStorage.getItem("tyzlle_"+data.tyzlle._id) ? JSON.parse(localStorage.getItem("tyzlle_"+data.tyzlle._id)) : null
        
        
                    console.log("Existing Resoonse", existingResponse)
        
                    if(existingResponse && existingResponse.colorgrid.length>0){
                        colorgrid    = existingResponse.colorgrid
                        letters      = existingResponse.letters
                        response     = existingResponse.response 
                        isCompleted  = existingResponse.isCompleted
                        wattempts    = existingResponse.wattempts
                        score        = existingResponse.score

                        if(existingResponse.correctLetters){
                            correctLetters = loadedTyzlle.resp.correctLetters
                        }

                        if(existingResponse.colorgridCurr){
                            colorgridCurr = existingResponse.colorgridCurr
                        }

        
                        for(var iC=0;iC<colorgrid.length; iC++){
                            if(colorgrid[iC] == 1){
                                colorgrid[iC] = 0
                            }
                        }
        
                        loadedTyzlle.resp.colorgrid = colorgrid
                        loadedTyzlle.resp.letters = letters
                        loadedTyzlle.resp.response = response
                        loadedTyzlle.resp.isCompleted = isCompleted
                        loadedTyzlle.resp.wattempts = wattempts
                        loadedTyzlle.resp.score = score

                        colorgridCurr = existingResponse.colorgridCurr
        
        
                        console.log("Exists Resoonse", loadedTyzlle)
        
                    }else{
                        var newTyzlleResp = {
                            "colorgrid":colorgrid,
                            "letters":letters,
                            "response":response,
                            "isCompleted":isCompleted,
                            "wattempts":[],
                            "score":0,
                            "colorgridCurr":[],

                        }
        
                        console.log("Doesnt Exists Resoonse", loadedTyzlle)
        
                        localStorage.setItem("tyzlle_"+data.tyzlle._id, JSON.stringify(newTyzlleResp))
                    }
        
                }



        dispatch({
            type: LOAD_TYZLLE,
            payload: loadedTyzlle //data
        })

        dispatch({
            type:TYZLLE_VIZ_HISTORY_INIT,
            payload:colorgridCurr
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const startTyzlle = (puzzleId) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/tyzlles/${puzzleId}`

        const { data } = await axios.get(full_url,config)

        console.log('puzzle : ',data)


        dispatch({
            type: START_TYZLLE,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: START_TYZLLE_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const tryWordTyzlle = (puzzle_id, sol_id, word, letters, colorgrid, wordSelection, vizColorgrid) => async (dispatch, getState) => {

    try{


        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        console.log("Try word ", puzzle_id, sol_id, word)

        const full_url = `/api/tyzlles/response/${puzzle_id}/${sol_id}`

        const { data } = await axios.post(full_url, {word, letters, colorgrid, wordSelection},config)

        console.log('puzzle : ',data)



        const updatedResponse = localStorage.getItem("tyzlle_"+puzzle_id) ? JSON.parse(localStorage.getItem("tyzlle_"+puzzle_id)) : null
        

        updatedResponse.letters     = data.letters
        updatedResponse.colorgrid   = data.colorgrid
        updatedResponse.isCompleted = data.isCompleted
        updatedResponse.wattempts   = [word, updatedResponse.wattempts]
        updatedResponse.score       = data.score
        updatedResponse.correctLetters = data.correctLetters
        updatedResponse.colorgridCurr = [...updatedResponse.colorgridCurr, data.colorgridCurr]
        
        
        for(var iV=0; iV<data.colorgridCurr; iV++){
            if(!vizColorgrid[data.colorgridCurr[iV]]){
                vizColorgrid[data.colorgridCurr[iV]] = updatedResponse.wattempts.length
            }
        }


        console.log("Updated Response ", updatedResponse)

        localStorage.setItem("tyzlle_"+puzzle_id, JSON.stringify(updatedResponse))

        //localStorage.setItem(puzzleId, JSON.stringify(data.presponse._id))

        dispatch({
            type: TYZLLE_ATTEMPT,
            payload: data
        })

        dispatch({
            type:TYZLLE_VIZ_HISTORY,
            payload:data.colorgridCurr
        })

        dispatch({
            type:TYZLLE_VIZ_COLORGRID,
            payload:vizColorgrid
        })


    }catch (error){

        dispatch({
            type: TYZLLE_ATTEMPT_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}
