import axios from 'axios';
// import {setAlert} from './alertActions';
import {

    LOAD_TYZLLE_CREATOR,
    LOAD_TYZLLE_CREATOR_ERROR,

    LOAD_TYZLLE_LIST_CREATOR,
    LOAD_TYZLLE_LIST_CREATOR_ERROR,
    ADD_TYZLLE_CREATOR,

    UPDATE_TYZLLE_CREATOR,
    UPDATE_TYZLLE_CREATOR_ERROR,

    POST_TYZLLE_CREATOR


} from '../constants/creatorConstants'


import {

    LOAD_TYZLLE,
    LOAD_TYZLLE_ERROR,
    INIT_TYZLLE,
    START_TYZLLE,
    START_TYZLLE_ERROR,
    TYZLLE_ATTEMPT,
    TYZLLE_ATTEMPT_ERROR,
    TYZLLE_CLEAR,
    TYZLLE_COMPLETED,
    TYZLLE_COMPLETED_ERROR,
    TYZLLE_RETAKE,

    LOAD_TYZLLE_LIST,
    LOAD_TYZLLE_LIST_ERROR,

    TYZLLE_VIZ_HISTORY,
    TYZLLE_VIZ_HISTORY_FAIL,
    TYZLLE_VIZ_HISTORY_INIT,
    TYZLLE_VIZ_COLORGRID

} from '../constants/tyzlleConstants'




//Load puzzle by id
export const loadTyzlleListCreator = () => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)

        // if(data && data._id){
        //     localStorage.setItem('tyzzleId', JSON.stringify(data._id))
        // }

        
        dispatch({
            type: LOAD_TYZLLE_LIST_CREATOR,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_LIST_CREATOR_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const loadTyzlleCreator = (tyzlle_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }

        const full_url = `/api/creator/${tyzlle_id}`

        const { data } = await axios.get(full_url,config)

        console.log("Tyzlle ", data)

        dispatch({
            type: LOAD_TYZLLE_CREATOR,
            payload: data
        })


    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_CREATOR_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const updateTyzlle = (puzzle_id, nrows, ncols, words) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/${puzzle_id}`

        const { data } = await axios.post(full_url, {nrows, ncols, words}, config)

        console.log('Update Tyzlle',data)


        dispatch({
            type: UPDATE_TYZLLE_CREATOR,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: UPDATE_TYZLLE_CREATOR_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const addTyzlle = () => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/`

        const { data } = await axios.post(full_url, {}, config)

        console.log('Update Tyzlle',data)


        dispatch({
            type: ADD_TYZLLE_CREATOR,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_LIST_CREATOR_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}








//Load puzzle by id
export const postTyzlle = (tyzlle_id, postDate) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/post/${tyzlle_id}`

        const { data } = await axios.post(full_url, {postDate}, config)

        console.log('Update Tyzlle',data)


        dispatch({
            type: POST_TYZLLE_CREATOR,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_CREATOR_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzle by id
export const loadTyzlleById = (tyzlle_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }

        console.log("TYZle url get ", tyzlle_id)


        const full_url = `/api/creator/play/${tyzlle_id}`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)

                //check if tyzlle is previously attempted by user in local storage and if so them load previous state
                var tyzlle = data.tyzlle
                var colorgrid = data.resp.colorgrid
                var letters   = data.resp.letters
                var response = data.response  
                var isCompleted = data.isCompleted
                var wattempts = []
                var score = 0
                var correctLetters = []
        
                var loadedTyzlle = {
                    "tyzlle":tyzlle,
                    "resp":{
                        "colorgrid":colorgrid,
                        "letters":letters,
                        "response":response,
                        "isCompleted":false,
                        "wattempts":[],
                        "score":0,
                        "correctLetters":[]
                    }
        
                }

                var colorgridCurr = []
        
                console.log("Loaded Tyzlle clean ", loadedTyzlle)
        
                if(data.tyzlle && data.tyzlle._id){
        
        
                    const existingResponse = localStorage.getItem("tyzlle_"+data.tyzlle._id) ? JSON.parse(localStorage.getItem("tyzlle_"+data.tyzlle._id)) : null
        
        
                    console.log("Existing Resoonse", existingResponse)
        
                    if(existingResponse && existingResponse.colorgrid.length>0){
                        colorgrid    = existingResponse.colorgrid
                        letters      = existingResponse.letters
                        response     = existingResponse.response 
                        isCompleted  = existingResponse.isCompleted
                        wattempts    = existingResponse.wattempts
                        score        = existingResponse.score

                        if(existingResponse.correctLetters){
                            correctLetters = loadedTyzlle.resp.correctLetters
                        }

                        if(existingResponse.colorgridCurr){
                            colorgridCurr = existingResponse.colorgridCurr
                        }

        
                        for(var iC=0;iC<colorgrid.length; iC++){
                            if(colorgrid[iC] == 1){
                                colorgrid[iC] = 0
                            }
                        }
        
                        loadedTyzlle.resp.colorgrid = colorgrid
                        loadedTyzlle.resp.letters = letters
                        loadedTyzlle.resp.response = response
                        loadedTyzlle.resp.isCompleted = isCompleted
                        loadedTyzlle.resp.wattempts = wattempts
                        loadedTyzlle.resp.score = score

                        colorgridCurr = existingResponse.colorgridCurr
        
        
                        console.log("Exists Resoonse", loadedTyzlle)
        
                    }else{
                        var newTyzlleResp = {
                            "colorgrid":colorgrid,
                            "letters":letters,
                            "response":response,
                            "isCompleted":isCompleted,
                            "wattempts":[],
                            "score":0,
                            "colorgridCurr":[],

                        }
        
                        console.log("Doesnt Exists Resoonse", loadedTyzlle)
        
                        localStorage.setItem("tyzlle_"+data.tyzlle._id, JSON.stringify(newTyzlleResp))
                    }
        
                }



        dispatch({
            type: LOAD_TYZLLE,
            payload: loadedTyzlle //data
        })

        dispatch({
            type:TYZLLE_VIZ_HISTORY_INIT,
            payload:colorgridCurr
        })

    
    }catch (error){

        dispatch({
            type: LOAD_TYZLLE_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}

