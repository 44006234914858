import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge, Dropdown } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'

import {loginUser} from '../actions/userActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const LoginScreen = ({match, history}) => {


    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const userR = useSelector(state => state.userR)
    const { user } = userR


    const loginUserRequest = () => {
        dispatch(loginUser(email, password))
    }

    useEffect(() => {

        if(user){
            history.push('/creator')
        }

    }, [user]);


    return (

        <>

                <InputGroup>
                
                <Form.Control type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}> 
                </Form.Control>

                <Form.Control type='text' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}> 
                </Form.Control>

                <Button onClick={()=>loginUserRequest()}>Log In</Button>
                
                </InputGroup>

              

        </>
    )
}


export default LoginScreen



