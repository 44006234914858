import React from 'react';
import {Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, userLogin: {userInfo}, ...rest }) => 
(<Route {...rest} render={props => !userInfo || !userInfo.isAdmin ? (<Redirect to='/login' />) : (<Component {...props} />)} />
)

PrivateRoute.propTypes = {
    userLogin: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    userLogin: state.userLogin
});

export default connect(mapStateToProps)(PrivateRoute);
