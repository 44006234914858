// Author : Anil Variyar
// License : Proprietary
// Property of : Anil Variyar/ Pixagan Technologies Private Limited

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap'



import PrivateRoute from './components/routing/PrivateRoute'
import AdminRoute from './components/routing/AdminRoute'


import Alert from './components/Alert'
import Header from './components/Header'
import Footer from './components/Footer'


import TyzlleScreen from './screens/TyzlleScreen'
import TyzlleListScreen from './screens/TyzlleListScreen'
import TyzlleViewScreen from './screens/TyzlleViewScreen'


import TyzlleListCreatorScreen from './screens/TyzlleListCreatorScreen'
import CreatorScreen from './screens/CreatorScreen'

import LoginScreen from './screens/LoginScreen'
import LogoutScreen from './screens/LogoutScreen'

const App = () => {
  return (

    <Router>
      <Header/>

      <main className='py-3'>

        <Container fluid>

          <Switch>

          <Route path='/' component={TyzlleScreen} exact />

          <Route path='/list' component={TyzlleListScreen} exact />

          <Route path='/play/:tyurl' component={TyzlleViewScreen} exact />



          <Route path='/admin3323/login' component={LoginScreen} exact />

          <PrivateRoute path='/creator' component={TyzlleListCreatorScreen} exact />

          <PrivateRoute path='/creator/:tyzlle_id' component={CreatorScreen} exact />

          <PrivateRoute path='/admin3323/logout' component={LogoutScreen} exact />

        

          <Route component={TyzlleScreen} />


          </Switch>
        

        </Container>



        
      </main>
      <Footer />
    </Router>
  )
}

export default App


