import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import FormContainer from '../../components/FormContainer'

import Dropdown from 'react-bootstrap/Dropdown';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const TyzllePlay = ({tyzlle}) => {


    const dispatch = useDispatch()
    const history = useHistory()

    const [colorgrid, setColorGrid] = useState({})
    const [selectedWordCol, setSelectedWordCol] = useState({})

    const alphabets_list = 'abcdefghijklmnopqrstuvwxyz'.split('');


    const colormap = {
        '-1':'#ffffff',
        '0':'#5f6e63', //'#c8ccc9',
        '1':'#bfab47',
        '2':'#4bbf73' //'#4fa163'
    }
    // '0':'#c7c2bd'



    useEffect(() => {

        

    }, [])


    return (

        <Container>

            <Card className="d-flex" style={{border:'None'}}>
            <Row className="align-self-center">
                <Col style={{border:'None'}}>


                <Card  style={{border:'None', padding:'5px'}}>


                <Card.Header style={{borderRadius:'20px', padding:'1px'}}>

                {
                    tyzlle && (
                        <p className='h3' style={{textAlign:'center', paddingTop:'0px', paddingBottom:'0px'}}>{tyzlle.title} </p>

                    )
                }

                </Card.Header>


                <Card.Body   style={{display:'flex', alignItems:'center', justifyContent:'center', border:'solid', borderColor:"#8b008b", margin:'10px', padding:'5px'}}>

                        
                {tyzlle && (

                    <> 

                <table striped bordered hover style={{width: isMobile?'90vw' : '30vh', height: isMobile?'90vw':'30vh',    
                        borderCollapse:'separate',              
                        borderSpacing:'0px',
                        borderWidth:'1px'
                        }}>
                    <tbody>

                    {Array(tyzlle.nrows).fill(0).map((val, index1) =>(

                        <tr key={100 + index1}>

                            {Array(tyzlle.ncols).fill(0).map((val, index2) =>(

                                <>
                                
                                    <td style={{
                                        backgroundColor: (tyzlle.colorgrid && tyzlle.colorgrid[index1*10+index2]) ? colormap[tyzlle.colorgrid[index1*10 + index2]] : colormap[0], 
                                        opacity:'0.8', 
                                        width: isMobile ? '8vw' :'4vh', 
                                        height: isMobile ? '8vw' :'4vh', 
                                        border:'solid', //'solid',
                                        textAlign: 'center', verticalAlign: 'middle',
                                        borderColor: 'white',
                                        padding:'1px',
                                        margin:'0px',
                                        borderCollapse:'separate',
                                        borderSpacing:'1px'
                                    }} >

                                        <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{' '}</p>
                                
                                    </td>

                                </>

                            ))}
                            
                        
                        </tr>

                    ))}


                    </tbody>
                </table>

                </>
                )}

                </Card.Body>

                </Card>



                </Col>
            </Row>

            </Card>

        </Container>
    )
    

}


export default TyzllePlay




