import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import {loadTyzlleListCreator, addTyzlle} from '../actions/creatorActions'

import TyzlleAdminListView from '../components/tyzlle/TyzlleAdminListView'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";






export const TyzlleListCreatorScreen = ({match, history}) => {


    const dispatch = useDispatch()

    const tyzlleCList = useSelector(state => state.tyzlleCList)
    const { ctyzlles } = tyzlleCList


    const editTyzlleRequest = (tyzlle_id) => {
        history.push(`/creator/${tyzlle_id}`)
    }


    const addTyzlleRequest = () => {
        dispatch(addTyzlle())
    }


    useEffect(() => {

        dispatch(loadTyzlleListCreator())
    }, [])


    //const last7 = []



    return (

        <>
             
                <div style={{margin:'20px'}}>


                    <Button onClick={()=>addTyzlleRequest()}>
                        Add
                    </Button>


                
                    <Row>
                    {ctyzlles && ctyzlles.map((ctyzlle, index)=>(
                        <Col style={{maxWidth:'30vw', minWidth:'20vw'}} onClick={()=>editTyzlleRequest(ctyzlle._id)}>
                        <TyzlleAdminListView tyzlle={ctyzlle} />
                    </Col>
                    ))}
                    

                    </Row>
                    


            
                </div>




        </>
    )
}


export default TyzlleListCreatorScreen



