import React from 'react';
import {Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, userR: {user}, ...rest }) => 
(<Route {...rest} render={props => !user ? (<Redirect to='/login' />) : (<Component {...props} />)} />
)

PrivateRoute.propTypes = {
    userR: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    userR: state.userR
});

export default connect(mapStateToProps)(PrivateRoute);
