import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  Row, Col } from 'react-bootstrap'
import {Nav  } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, ListGroup, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'



const Footer = () => {



    return (
        <footer className="footer">


            <Container>

                <hr />
            <Row className='show-grid'>

                <Col>
                <LinkContainer to='/termsandconditions'>
                    <Nav.Link>
                            T&C

                    </Nav.Link>
                </LinkContainer>
                </Col>

            </Row>

            <hr/>

            <Row>

            <Col className='text-center py-3 mt-0'>
                &copy; 2021-2024, Pixagan&reg; Technologies Pvt. Limited. All rights reserved.  
            </Col>

            </Row>



                </Container>




        </footer>
    )
}

export default Footer



