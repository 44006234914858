import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'


import TyzlleCreate from '../components/tyzlle/TyzlleCreate'
import TyzllePlay from '../components/tyzlle/TyzllePlay' 


import {loadTyzlleCreator, loadTyzlleById} from '../actions/creatorActions'



export const CreatorScreen = ({match, history, socket}) => {


    const dispatch = useDispatch()

    const tyzlle_id = match.params.tyzlle_id

    const tyzlleCR = useSelector(state => state.tyzlleCR)
    const { tyzlle, solution } = tyzlleCR


    const [viewMode, setViewMode] = useState('create')
    

    const toggleViewMode = (mode_in) => {

        if(mode_in == 'play'){
            playTyzlleRequest()
        }

        setViewMode(mode_in)
    }

    const playTyzlleRequest = () => {
        dispatch(loadTyzlleById(tyzlle_id))
    }


    useEffect(() => {

        dispatch(loadTyzlleCreator(tyzlle_id))
    }, [])


    //const last7 = []



    return (

        <>

                <InputGroup>
                    <Button onClick={()=>toggleViewMode('create')}>Create</Button>
                    <Button onClick={()=>toggleViewMode('play')}>Play</Button>
                </InputGroup>

              

                {viewMode == 'create' && (
                    <>

                    {tyzlle && (
                    <TyzlleCreate tyzlle={tyzlle} solution={solution}/>
                    )}

                    </>
                )}

                


                {viewMode == 'play' && (
                    <TyzllePlay/>
                )}
                

        </>
    )
}


export default CreatorScreen



