import axios from 'axios';
import {

    LOAD_TYZLLE,
    LOAD_TYZLLE_ERROR,
    INIT_TYZLLE,
    START_TYZLLE,
    START_TYZLLE_ERROR,
    TYZLLE_ATTEMPT,
    TYZLLE_ATTEMPT_ERROR,
    TYZLLE_CLEAR,
    TYZLLE_COMPLETED,
    TYZLLE_COMPLETED_ERROR,
    TYZLLE_RETAKE,

    LOAD_TYZLLE_LIST, 
    LOAD_TYZLLE_LIST_ERROR,

    TYZLLE_VIZ_HISTORY,
    TYZLLE_VIZ_HISTORY_FAIL,
    TYZLLE_VIZ_HISTORY_INIT,
    TYZLLE_VIZ_COLORGRID

} from '../constants/tyzlleConstants'



export const TyzlleReducer = (state = {tyzlle: null, colorgrid:{}, letters:{}, response:{}, isCompleted:false, wattempts:[], score:0}, action) => {

    switch(action.type){

        case LOAD_TYZLLE:
            return { loading: false, tyzlle: action.payload.tyzlle, colorgrid:action.payload.resp.colorgrid, letters:action.payload.resp.letters, response:action.payload.resp, isCompleted:false, wattempts:action.payload.resp.wattempts, score:action.payload.resp.score }

        case INIT_TYZLLE:
            return { loading: false, tyzlle: action.payload.tyzlle, colorgrid:action.payload.resp.colorgrid, letters:action.payload.resp.letters, response:action.payload.resp, isCompleted:false, wattempts:action.payload.resp.wattempts, score:action.payload.resp.score }

        case LOAD_TYZLLE_ERROR:
            return { loading: false, error: action.payload, tyzlle:state.tyzlle, colorgrid:state.colorgrid, letters:state.letters, response:state.response, isCompleted:false, wattempts:[], score:0 }

        case START_TYZLLE:
            return { loading: false, tyzlle: state.tyzlle, colorgrid:action.payload, letters:{}, response:{},  isCompleted:false, wattempts:[] }

        case START_TYZLLE_ERROR:
            return { loading: false, error: action.payload, tyzlle:state.tyzlle, colorgrid:state.colorgrid, letters:state.letters, response:{}, isCompleted:false, wattempts:[] }

        case TYZLLE_ATTEMPT:
            return { loading: false, tyzlle: state.tyzlle, colorgrid:action.payload.colorgrid, letters:action.payload.letters, response:{}, isCompleted:action.payload.isCompleted, wattempts:[action.payload.word, ...state.wattempts], score:action.payload.score }

        case TYZLLE_ATTEMPT_ERROR:
            return { loading: false, error: action.payload, tyzlle:state.tyzlle, colorgrid:state.colorgrid, letters:state.letters, response:{}, isCompleted:false, wattempts:state.wattempts, score:state.score }

        case TYZLLE_RETAKE:
            return { loading: false, error: null, tyzlle:state.tyzlle, colorgrid: {}, letters:{}, response:{}, isCompleted: false, wattempts:[], score:0 }


        default:
            return state
    }


}


export const TyzlleVizReducer = (state = {attempts:[], vizColorgrid:{}}, action) => {

    switch(action.type){

        case TYZLLE_VIZ_HISTORY:
            return { loading: false, attempts: [...state.attempts, action.payload], vizColorgrid:state.vizColorgrid }

        case TYZLLE_VIZ_HISTORY_FAIL:
            return { loading: false, error: action.payload, attempts:state.attempts, vizColorgrid:state.vizColorgrid }

        case TYZLLE_VIZ_HISTORY_INIT:
            return { loading: false, attempts: action.payload, vizColorgrid:state.vizColorgrid }

        case TYZLLE_VIZ_COLORGRID:
            return { loading: false, attempts: state.attempts, vizColorgrid:action.payload }


        default:
            return state
    }


}


export const TyzlleKeyboardsReducer = (state = {keyboard1:{},keyboard2:{}, keyboard3:{}, keyboard4:{}, keyboard5:{}}, action) => {

    switch(action.type){

        case LOAD_TYZLLE_LIST:
            return { loading: false, keyboards: action.payload }

        case LOAD_TYZLLE_LIST_ERROR:
            return { loading: false, error: action.payload, keyboards:state.tyzlles }


        default:
            return state
    }


}




export const TyzlleListReducer = (state = {tyzlles:[]}, action) => {

    switch(action.type){

        case LOAD_TYZLLE_LIST:
            return { loading: false, tyzlles: action.payload }

        case LOAD_TYZLLE_LIST_ERROR:
            return { loading: false, error: action.payload, tyzlles:state.tyzlles }


        default:
            return state
    }


}


