export const LOAD_TYZLLE  = 'LOAD_TYZLLE'
export const LOAD_TYZLLE_ERROR  = 'LOAD_TYZLLE_ERROR'
export const INIT_TYZLLE  = 'INIT_TYZLLE'

export const START_TYZLLE  = 'START_TYZLLE'
export const START_TYZLLE_ERROR  = 'START_TYZLLE_ERROR'

export const TYZLLE_ATTEMPT  = 'TYZLLE_ATTEMPT'
export const TYZLLE_ATTEMPT_ERROR  = 'TYZLLE_ATTEMPT_ERROR'

export const TYZLLE_CLEAR  = 'TYZLLE_CLEAR'

export const TYZLLE_COMPLETED  = 'TYZLLE_COMPLETED'
export const TYZLLE_COMPLETED_ERROR  = 'TYZLLE_COMPLETED_ERROR'

export const TYZLLE_RETAKE = 'PUZZLE_RETAKE'


export const LOAD_TYZLLE_LIST  = 'LOAD_TYZLLE_LIST'
export const LOAD_TYZLLE_LIST_ERROR  = 'LOAD_TYZLLE_LIST_ERROR'


export const TYZLLE_VIZ_HISTORY  = 'TYZLLE_VIZ_HISTORY'
export const TYZLLE_VIZ_HISTORY_INIT  = 'TYZLLE_VIZ_HISTORY_INIT'
export const TYZLLE_VIZ_HISTORY_FAIL  = 'TYZLLE_VIZ_HISTORY_FAIL'

export const TYZLLE_VIZ_COLORGRID  = 'TYZLLE_VIZ_COLORGRID'