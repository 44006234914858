import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import FormContainer from '../../components/FormContainer'

import Dropdown from 'react-bootstrap/Dropdown';

import {loadTyzlle, startTyzlle, tryWordTyzlle, loadTyzlleUrl} from '../../actions/tyzlleActions'



import QRCode from "react-qr-code";
import TyzlleInstructions from './TyzlleInstructions'
import TyzlleCompleted from './TyzlleCompleted'

import VirtualKeyboardCard from './VirtualKeyboardCard'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const TyzllePlay = ({}) => {

 


    const dispatch = useDispatch()
    const history = useHistory()

    const [wordSelection, setWordSelection ] = useState(0)
    const [wordEntered, setWordEntered] = useState([])
    const [wgrid, setWGrid] = useState({})
    const [selectedWordCol, setSelectedWordCol] = useState({})
    const [attemptsRemaining, setAttemptsRemaining] = useState(20)


    const [showInstructions, setShowInstructions] = useState(false)

    const tyzlleR = useSelector(state => state.tyzlleR)
    const { tyzlle, colorgrid, letters, isCompleted, response, wattempts } = tyzlleR


    const tyzlleViz = useSelector(state => state.tyzlleViz)
    const { vizColorgrid } = tyzlleViz



    const alphabets_list = 'abcdefghijklmnopqrstuvwxyz'.split('');


    const colormap = {
        '-1':'#ffffff',
        '0':'#5f6e63', //'#c8ccc9',
        '1':'#bfab47',
        '2':'#4bbf73' //'#4fa163'
    }
    // '0':'#c7c2bd'




    const setWordKeyboardButtons = (key_in) => {

        if(key_in == 'CLEAR'){
            setWordEntered([])
            setWGrid({})

        }else if (key_in == 'ENTER'){
            dispatch(tryWordTyzlle(tyzlle._id, response._id,wordEntered, letters, colorgrid, wordSelection, vizColorgrid))
            setWordEntered([])
            setAttemptsRemaining(attemptsRemaining - 1)
            //setWGrid({})

        }else if((alphabets_list.includes(key_in) || alphabets_list.includes(key_in.toUpperCase())) && (key_in>='a' && key_in<='z') || (key_in>='A' && key_in <= 'Z')){
            var cword = wordEntered
            console.log("Key in ", key_in, wordEntered)

            if(wordEntered.length <= 5){

                if(wordEntered.length == 0){
                    setWGrid({})
                }
    
                
                
                var cwgrid = wgrid

                var iW = wordSelection
                //for(var iW=0;iW<5; iW++){
    
                    var iL = wordEntered.length
                    var cpos = tyzlle.words[iW].positions[iL]
                    cwgrid[cpos] = key_in
                    
                //}
    
                setWGrid(cwgrid)
                
                
                
                setWordEntered(wordEntered => [...wordEntered, key_in] )
    

            }



        }
 
    }


    const selectWord = (index_in) => {

        if(tyzlle && tyzlle.words) {

            for (var iW=0; iW<5; iW++){
                if(tyzlle.words[iW].positions.includes(index_in)){
                    setWordSelection(iW)
                    var cSel = {}
                    for(var iL=0; iL<5; iL++){
                        
                        cSel[tyzlle.words[iW].positions[iL]] = 1
                        
                    }
                    setSelectedWordCol(cSel)
                }
            }


        }

        
    }

    const DocumentKeyUp = (key_in) => {

        //key_in.preventDefault()

        console.log('Document : ', key_in)
        console.log('Key ', key_in.key, wordSelection)

        if(key_in.key == 'Backspace'){
            setWordKeyboardButtons('CLEAR')
        }else if(key_in.key == 'Enter'){
            setWordKeyboardButtons('ENTER')
        }else{
            setWordKeyboardButtons(key_in.key.toLowerCase())
        }

        

    }

    const submitWord = () => {
        //dispatch(tryWord())
    }

    const startPuzzleRequest = () => {
        dispatch(startTyzlle())
    }


    useEffect(() => {

        //dispatch(startPuzzle(puzzle._id))
        // if(!tyzlle){
        //     if(tyurl){
        //         console.log("TY URL ", tyurl)
        //         dispatch(loadTyzlleUrl(tyurl))
        //     }else{
        //         dispatch(loadTyzlle())
        //     }

        // }


        if(!isMobile){

        
            window.addEventListener("keyup", DocumentKeyUp);
    
            return () => {
              window.removeEventListener("keyup", DocumentKeyUp);
            };
    
        }
    

        

    }, [tyzlle, DocumentKeyUp, isCompleted])


    return (

        <Container>

            <Card className="d-flex" style={{border:'None'}}>
            <Row className="align-self-center">
                <Col style={{border:'None'}}>


                <Card  style={{border:'None', padding:'5px'}}>


                <Card.Header style={{borderRadius:'20px', padding:'1px'}}>

                {
                    tyzlle && (
                        <p className='h3' style={{textAlign:'center', paddingTop:'0px', paddingBottom:'0px'}}>{tyzlle.title} </p>

                    )
                }

                </Card.Header>

                <Card.Header className="d-flex" style={{padding:'0px', backgroundColor:'white', border:'None'}}>
                    <InputGroup className="align-self-center" style={{margin:'0px', padding:'0px'}}>

                        <Button variant='success' style={{paddingTop:'0px', paddingBottom:'0px', backgroundColor:'#8b008b', color:'white'}} onClick={()=>setShowInstructions(showInstructions => showInstructions = !showInstructions)}>Instructions</Button>

                        <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:'#8b008b', color:'white'}}>
                            Attempts : {attemptsRemaining}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {wattempts && wattempts.map((cattempt, index)=>(
                                <Dropdown.Item>{cattempt}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        </Dropdown>

                        {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:'#8b008b', color:'white'}}>
                            Word Letters
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown> */}



                    </InputGroup>
                </Card.Header>

                {showInstructions && (
                    <TyzlleInstructions />
                )}
                

                {isCompleted && (
                    <TyzlleCompleted tyzlle={tyzlle} puzzle_id={tyzlle._id} isCompleted={isCompleted} score={attemptsRemaining+1}/>
                )}

                {/* <TyzlleCompleted/> */}
                


                <Card.Body   style={{display:'flex', alignItems:'center', justifyContent:'center', border:'None', borderColor:"#8b008b", margin:'10px', padding:'5px'}}>

                        
                {tyzlle && (

                    <> 

                <table striped bordered hover style={{width: isMobile?'90vw' : '50vh', height: isMobile?'90vw':'50vh',    
                        borderCollapse:'separate',              
                        borderSpacing:'0px',
                        borderWidth:'1px',
                        padding:'10px',
                        border:'solid', borderColor:"#8b008b"
                        }}>
                    <tbody>

                    {Array(tyzlle.nrows).fill(0).map((val, index1) =>(

                        <tr key={100 + index1}>

                            {Array(tyzlle.ncols).fill(0).map((val, index2) =>(

                                <>
                                
                                    <td style={{
                                        backgroundColor: (colorgrid && colorgrid[index1*10+index2]) ? colormap[colorgrid[index1*10 + index2]] : colormap[0], 
                                        opacity:'0.8', 
                                        width: isMobile ? '8vw' :'4vh', 
                                        height: isMobile ? '8vw' :'4vh', 
                                        border:'solid', //'solid',
                                        textAlign: 'center', verticalAlign: 'middle',
                                        borderColor: (selectedWordCol[index1*10 + index2] ? '#8b008b' : 'white'),
                                        padding:'1px',
                                        margin:'0px',
                                        borderCollapse:'separate',
                                        borderSpacing:'1px'
                                    }}  onClick={()=>selectWord(index1*10 + index2)}>
                                        
                      


                                        { (letters && letters[index1*10 + index2]) ? (
                                             <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{letters[10*index1 + index2]}</p>
                                         ) : (
                                             <>
                                                
                                                {wgrid && wgrid[index1*10 + index2] && (
                                                     <p className='h3 gridp' style={{left:'1vw', color:'white'}}>{wgrid[10*index1 + index2]}</p>
                                                 )}
                                             </>
                                        )}

                                        


                                    </td>

                                </>

                            ))}
                            
                        
                        </tr>

                    ))}


                    </tbody>
                </table>

                </>
                )}

                </Card.Body>


                <Card className="d-flex" style={{border:'None', marginBottom:'15px', height:'4vw'}}>
                    <Row className="align-self-center">
                        <Col>
                            <table>
                                <tr>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[0]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[1]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[2]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[3]}</td>
                                    <td className='h3' style={{border:'solid', borderColor:'#8b008b',width:'4vw', height:'4vw', borderRadius:'20px', textAlign: 'center', verticalAlign: 'middle'}}>{wordEntered[4]}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Card>




                {/* <Card style={{width:isMobile?'95vw':'40vh', border:'None', padding:'0px', marginTop:'5px'}}>

                <Table size='sm' style={{width:isMobile?'95vw':'40vh', padding:'1px', margin:'1px', border:'None'}}>
                <tbody>
                    <tr>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('q')}>q</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('w')}>w</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('e')}>e</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('r')}>r</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('t')}>t</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('y')}>y</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('u')}>u</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('i')}>i</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('o')}>o</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('p')}>p</Button></td>
                    </tr>
                    </tbody>
                </Table>

                <Table size='sm' style={{width:isMobile?'95vw':'40vh', padding:'1px', margin:'1px', border:'None'}}>
                <tbody>
                <tr>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('a')}>a</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('s')}>s</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('d')}>d</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('f')}>f</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('g')}>g</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('h')}>h</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('j')}>j</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('k')}>k</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('l')}>l</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('CLEAR')}>CLEAR</Button></td>
                    </tr>
                </tbody>
                </Table>

                <Table size='sm' style={{width:isMobile?'95vw':'40vh', padding:'1px', margin:'1px', border:'None'}}>
                <tbody>
                    <tr>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('z')}>z</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('x')}>x</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('c')}>c</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('v')}>v</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('b')}>b</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('n')}>n</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('m')}>m</Button></td>
                        <td><Button className={isMobile?'keyboardPMob' :'keyboardP'} onClick={()=>setWordKeyboardButtons('ENTER')}>ENTER</Button></td>
                        <td></td>
                    </tr>
                </tbody>

                </Table>

                </Card> */}

                <div style={{width:isMobile?'98vw':'50vw', padding:'10px'}}>
                    <VirtualKeyboardCard setWordKeyboardButtons={setWordKeyboardButtons}/>


                </div>
 


                </Card>

      



                </Col>
            </Row>

            </Card>

        </Container>
    )
    

}


export default TyzllePlay




